import React, { useEffect, useState } from "react";
import Layout from "../../layouts/Layout";
import { graphql } from "gatsby";
import { Col, Row, Container } from "react-bootstrap";
import accordion from "../../helper/accordion";
import bountySectionHeader from "../../helper/bounty-section-header";
import Toc from "../../components/Toc";
import OnImagesLoaded from "react-on-images-loaded";

const BountiesPage = ({ pageContext, data, location }) => {
  const [imagesLoaded, setImagesLoaded] = useState(false);
  // initialize accordion and section header functionality on render
  useEffect(() => {
    accordion.init();
    bountySectionHeader.init();
  }, []);

  const page = data.page;
  const stringTranslations = pageContext.stringTranslations;

  return (
    <Layout
      context={pageContext}
      className="bounty-page"
      useContainer={false}
      location={location}
      progressBarTarget="article"
    >
      <article className="article">
        <Container>
          <Row>
            <Col lg={{ span: 6, offset: 1 }}>
              <header>
                {page.post_parent && (
                  <p className="roofline" dangerouslySetInnerHTML={{__html: page.post_parent.name}} />
                )}
                <h1 dangerouslySetInnerHTML={{__html: page.post_title}} />
              </header>
              <OnImagesLoaded
                onLoaded={() => setImagesLoaded(true)}
                onTimeout={() => setImagesLoaded(true)}
                timeout={3000}
              >
                <div
                  className="content"
                  dangerouslySetInnerHTML={{ __html: page.post_content }}
                />
              </OnImagesLoaded>
            </Col>
            <Col lg={{ span: 3, offset: 1 }}>
              <Toc enter={150} title={stringTranslations["Contents"]} init={imagesLoaded} />
            </Col>
          </Row>
        </Container>
      </article>
    </Layout>
  );
};

export const query = graphql`
  query BountiesQuery($id: Int!) {
    page: wordpressPage(wordpress_id: { eq: $id }) {
      post_title
      post_excerpt
      wordpress_id
      path
      post_content
      post_parent {
        name
      }
    }
  }
`;

export default BountiesPage;
