/**
 * Creates Bounty Section Header functionality to custom gutenberg blocks
 * @type {{init: accordion.init, select: accordion.select}}
 */
const bountySectionHeader = {
  init: function() {
    var buttons = document.querySelectorAll(".bounty-section-btn");
    if (buttons.length > 0) {
      for (var button of buttons) {
        button.addEventListener("click", bountySectionHeader.click);
      }
    }
  },

  click: function(element) {
    var input = element.target.parentElement.querySelector(
      ".bounty-section-link-input"
    );
    var url = element.target.parentElement.querySelector(
      ".bounty-section-url-result"
    );
    var baseUrl = url.getAttribute("data-baseurl");

    if (input && url && baseUrl) {
      var resultUrl = baseUrl + `?tag=${input.value}`;
      url.innerHTML = `<a href="${resultUrl}" target="_blank">${resultUrl}</a>`;
    }
  }
};

export default bountySectionHeader;
